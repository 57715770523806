import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import spriteSVG from "../sprite/sprite-build.svg"

const IndexPage = ({ data }) => {

  const posts = data.blog.nodes

  return (
    <Layout>
      <Helmet>
        <body className="home"></body>
      </Helmet>
      <SEO title="Home" />

      <section className="c-section c-section--hero">

        <div className="o-container o-v-middle">
          <div className="c-hero-content">
            <div className="c-hero-content__logo"><svg className="u-fill-white" viewBox="0 0 332 217"><use xlinkHref={spriteSVG + "#LogoBig"}></use></svg></div>
            <h1 className="c-hero-content__title">Privacy Preserving <br />Accountable Decryption</h1>

            <p><a href="https://e4ufd97gowu.typeform.com/to/lJPnKDSr" className="c-button">Join the Wait List</a></p>

          </div>

        </div>

      </section>
      
      <section className="c-section c-section--gradient" id="pitch">

        <div className="o-container">
          
          <div className="o-grid o-type-2">
            <div className="o-grid__col u-1/2@md">
              <p>Transacting online today allows us to operate in only two modes: complete openness or complete privacy. Because of this, at times we share more than we should, and at other times we share less.</p>
            </div>
          </div>

          <div className="o-type-4 u-mv-x4 u-mv-x8@md">
            <p><em><strong>PAD</strong> is a new approach to maintaining privacy in the management of sensitive information. It allows anyone to share the ability to access to a piece of information, whilst creating transparency as to whether that information has actually been accessed.</em></p>
          </div>
          
          <div className="o-grid o-type-2">
            <div className="o-grid__col u-1/2@md u-push-1/2@md">
              <p>Anytime you need to encrypt and share a piece of information, where the rules of who, when and/or how that information should be decrypted and accessed cannot be simply codified, <strong>PAD</strong> can help to improve privacy and oversight of the information.</p>
            </div>
          </div>

        </div>

      </section>
      
      <section className="c-section" id="use-cases">

        <div className="o-container">
          
          

          <div className="">
            <h2 className="c-section__title">Use Cases</h2>

            <div className="o-grid o-grid--cards o-type-2">
              <div className="o-grid__col c-cards u-1/2@md">
                <h3 className="c-cards__title c-cards__title--cases"><svg className="u-fill-compliment u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconUser"}></use></svg>Find Me</h3>
                <p>Today, people have the choice to always share their location with someone, time-limit sharing or never share it at all. With PAD, it’s possible to share the ability to access your location with a close friend or loved one. If you’re in trouble, your sharing partner can access your location, but this will immediately notify you that your location has been accessed. </p>
              </div>
              <div className="o-grid__col c-cards u-1/2@md">
                <h3 className="c-cards__title c-cards__title--cases"><svg className="u-fill-compliment u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconCard"}></use></svg>Cold Wallets</h3>
                <p>Crypto key losses can be devastating. With PAD, you can delegate access to your crypto keys to a group of trusted friends. Access can be setup such that if the whole group agrees, the crypto key can be decrypted and recovered. As an additional security feature, access can be delayed. This allows you to be notified up to 24 hours before in the case of unauthorised use. </p>
              </div>
              <div className="o-grid__col c-cards u-1/2@md">
                <h3 className="c-cards__title c-cards__title--cases"><svg className="u-fill-compliment u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconCloud"}></use></svg>Privacy-first delivery</h3>
                <p>When ordering goods online, we share our name and address with the vendor to facilitate delivery. With PAD, your name and address can be sent to the vendor locked, and only accessed by the delivery company when they receive the package at a depot. Regional information can still be shared to negotiate shipping options without compromising the separation of orders and individuals</p>
              </div>
              <div className="o-grid__col c-cards u-1/2@md">
                <h3 className="c-cards__title c-cards__title--cases"><svg className="u-fill-compliment u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconLettter"}></use></svg>Regulatable Payment Systems</h3>
                <p>In order for decentralised payment systems to be regulatable, it must be possible to associate a real-world identity with individual payments. PAD provides a mechanism to associate an identity, which is to be accessed by regulators only when reasonable and necessary. </p>
              </div>

            </div>
          </div>
          

        </div>

      </section>
      
      <section className="c-section c-section--dark" id="blog">

        <div className="o-container">
          
          

          <div className="">

            <div className="o-title-text u-ai-center u-mb-x4 u-mb-x8@md">
              <h2 className="c-section__title u-mb-x2 u-mb-none@md">Latest blog posts</h2>
              <Link to="/blog" className="c-button">All blog posts</Link>
            </div>

            <div className="o-grid o-grid--cards o-type-2">
              {
                posts.map(post => (

                  <div key={post.id} className="o-grid__col c-blog-post u-1/2@md">
                    <Link to={post.fields.slug} className="c-blog-post__top">
                      <h3 className="c-blog-post__title">{post.frontmatter.title}</h3>
                      <figure className="c-blog-post__figure"><Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} alt={post.frontmatter.title} /></figure>
                      <div className="c-blog-post__excerpt">
                        <p>{post.frontmatter.excerpt}</p>
                      </div>
                    </Link>
                    <div className="c-blog-post__bottom">
                      <Link to={post.fields.slug} className="c-button">Read more...</Link>
                    </div>
                  </div>

                ))
              }

            </div>
          </div>
          

        </div>

      </section>

      
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    blog: allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } }, frontmatter: { language: { eq: "English" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      nodes {
        id
        fields {
          slug
        }
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 600) {
                presentationHeight
                sizes
                src
                srcSet
                presentationWidth
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`